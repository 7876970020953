
<template>
  <div>
    <div v-permission="'users.create'" class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{$t('AddNew')}}</span>
      </div>
    </div>
    <Grid :service="service" route_name="users"></Grid>
  </div>
</template>


<script>
import UserService from '@/services/api/UserService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/users/create')
    }
  },
  beforeMount() {
    this.service = UserService.build(this.$vs)
  }
}
</script>